import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import GetStarted from "components/cta/GetStarted";
import React from "react";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <GetStarted 
      text={"Desculpe o transtorno, estamos em manutenção no momento, estaremos online em breve!"}
      pushDownFooter={true}
      primaryLinkText={"WhatsApp"}
      primaryLinkUrl={"https://api.whatsapp.com/send?phone=5531986558506&text=Ol%C3%A1,%20vim%20pelo%20site%20da%20EGT%20Contabilidade."}
      primaryLinkTarget = "_blank"
      secondaryLinkText={"E-mail"}
      secondaryLinkUrl={"mailto:egt@egtcontabilidade.com.br"}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
