import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import LogoImage from "images/logo.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FourColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;
const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <FourColumns>
        <Column>
            <LogoContainer>
              {/* <LogoImg src={LogoImage} /> */}
              <LogoText>EGT Contabilidade</LogoText>
            </LogoContainer>
            <CompanyAddress>
              Rua dos Aimorés, 981 - Funcionários, Belo Horizonte - MG, 30140-071
            </CompanyAddress>
            <SocialLinksContainer>
           <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/egtcontabilidade/">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://linkedin.com">
              <LinkedinIcon />
            </SocialLink>
            </SocialLinksContainer>
          </Column>
          <Column>
            <ColumnHeading>Home</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/AboutUs">Quem somos?</Link>
              </LinkListItem>
              <LinkListItem>
              <Link href="#">Depoimentos de clientes</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/blog">Blog</Link>
              </LinkListItem>
              <LinkListItem>
              <Link href="/ContactUs">Contato</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Registro no CRC</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/PrivacyPolicy">Política de Privacidade</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/TermsOfService">Termos de serviço</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Assine a nossa newsletter</ColumnHeading>
              <SubscribeText>
                Fique por dentro das novidades, receba semanalmente artigos e notícias relevantes.
              </SubscribeText>
              <SubscribeForm method="get" action="#">
                <Input type="email" placeholder="Seu e-mail" />
                <SubscribeButton type="submit">Inscrever</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </FourColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            {/* <LogoImg src={LogoImage} /> */}
            {/* <LogoText>EGT Contabilidade</LogoText> */}
          </LogoContainer>
          <CopywrightNotice>&copy; 2021 EGT Contabilidade. Todos os direitos.</CopywrightNotice>
          <SocialLinksContainer>            
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
