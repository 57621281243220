import MainLandingPage from "MainLandingPage.js";
import AboutUs from "pages/AboutUs";
import BlogIndex from "pages/BlogIndex";
import Login from "pages/Login";
import MaintencePage from "pages/MaintencePage";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "styles/globalStyles.css";
import "tailwindcss/dist/base.css";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";

var switchRoute;

var isInMaintence = true; 
if (isInMaintence) {
  switchRoute = 
  <Switch>
    {<Route path="/TermsOfService" component={TermsOfService} />}
    {<Route path="/PrivacyPolicy" component={PrivacyPolicy} />}
    {<Route path="*" component={MaintencePage} />}
  </Switch>
} else {
  switchRoute =   
  <Switch>
    {<Route path="/TermsOfService" component={TermsOfService} />}
    {<Route path="/PrivacyPolicy" component={PrivacyPolicy} />}
    {<Route path="/ContactUs" component={ContactUs} />}
    {<Route path="/blog" component={BlogIndex} /> }
    {<Route path="/AboutUs" component={AboutUs} /> }
    {/* {<Route path="/Cases" component={Cases} /> } */}
    {<Route path="/login" component={Login} /> };
    {<Route path="/*" component={MainLandingPage} /> };
  </Switch>
}

export default function App() {
  return (
    <BrowserRouter >
      <Switch>
      {switchRoute}
      </Switch>
    </BrowserRouter>
  );
}
